function getRequest(url:URL): { provider:string|undefined, url: string|undefined } {
    switch (url.hostname) {
    case 'www.youtube.com':
    case 'youtube.com':
    case 'youtu.be':
        return { provider: 'youtube', url: `https://youtube.com/oembed?url=${url}&rel=0` }
    case 'www.twitter.com':
    case 'twitter.com':
    case 'x.com':
    case 'www.x.com':
        return { provider: 'twitter', url: url.pathname.split('/').pop() || '' }
    }

    return { provider: undefined, url: undefined }
}

export class Twitter {
    private twttr

    async loadTwitter() {
        return new Promise((resolve, reject) => {
            if (this.twttr) {
                resolve(this.twttr)
            }
            if (window.twttr) {
                this.twttr = window.twttr
                resolve(this.twttr)
            }
            try {
                const script = document.createElement('script')
                script.setAttribute('id', 'twttr')
                script.setAttribute('src', 'https://platform.twitter.com/widgets.js')
                script.setAttribute('async', 'false')
                script.addEventListener('load', () => {
                    this.twttr = window.twttr
                    resolve(this.twttr)
                })

                script.addEventListener('error', () => {
                    reject(new Error('Failed to load Twitter script'))
                })
                document.body.appendChild(script)
            } catch (error) {
                reject(error)
            }
        })
    }

    appendTweet(el:Element, id:string) {
        if (el == null) {
            return
        }
        this.twttr.widgets.createTweet(id, el)
    }
}

async function replacer(match: string, p1?: string) {
    if (!p1) {
        return match
    }
    let url: URL
    try {
        url = new URL(p1)
    } catch (e) {
        return match
    }
    if (!url) {
        return match
    }
    const req = getRequest(url)
    if (!req || !req.provider) {
        return match
    }
    if (req.provider === 'twitter') {
        return new Promise((resolve) => {
            // eslint-disable-next-line camelcase
            resolve({ type: 'tweet', provider_name: 'twitter', url: req.url })
        })
    }
    try {
        const json = await fetch(new Request(req.url)).then((res) => res.json())
        return json
    } catch (e) {
        return null
    }
}

export async function loadTweets() {
    const t = new Twitter()
    await t.loadTwitter()
    const tweets = document.getElementsByClassName('twitter-holder')
    Array.from(tweets).forEach((tweet:Element) => {
        const [, id] = tweet.id.split('-')
        t.appendTweet(tweet, id)
    })
}

export async function oembed(content:string): Promise<string> {
    const tagRegex = /<oembed\s+url="([\S^"^']*)">\s*<\/\s*oembed>/gm
    const promises: Array<Promise<any>> = []
    content.replace(tagRegex, (match: string, p1?: string) => {
        const promise = replacer(match, p1)
        promises.push(promise)
    })
    const data = await Promise.all(promises)
    const newContent = content.replace(tagRegex, () => {
        const json = data.shift()
        if (!json || json.provider_name === 'twitter') {
            return `<div class="twitter-holder" id="t-${json.url}"></div>`
        }
        return `<div class="oembed-${json.type} oembed-${json.provider_name.toLowerCase()}">${json.html}</div>`
    })
    return new Promise((resolve) => {
        resolve(newContent)
    })
}
